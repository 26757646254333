import buttons from './buttons'

export default {
  // ? ==========================
  // ? ====  Locaition Page  ====
  // ? ==========================
  reservations: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    flexDirection: 'column',
    '.title': {
      marginBottom: '2rem'
    }
  },

  locationsPage: {
    '.logo': {
      maxWidth: ['175px', '', '200px', '300px'],
      top: [6, '', '', '']
    }
    // '.Fairfield': {
    //   flexGrow: 1,
    //   '.locationTitle': {
    //     display: 'flex',
    //     flexDirection: 'column'
    //   },
    //   '.CTAS': {
    //     minWidth: ['175px', '', '200px', '500px'],
    //     a: {
    //       display: 'none'
    //     }
    //   }
    // }
    // 	.Norwalk .locationTitle:{
    // :after: {
    // content:"'Coming soon'"
    // }
    // '.CTAS .CTAButton': {
    //   border: 'solid 2px white',
    //   fontFamily: 'Gochi Hand',
    // },
  },

  // ? ==========================
  // ? =======  Layout  =========
  // ? ==========================

  navigation: {
    '.mobileLocationSwitcher': {
      zIndex: '2000'
    },
    '.locationSwitcher, .mobileLocationSwitcher': {
      '.button': {
        variant: 'buttons.primary'
      },
      '.popUp': {
        '.content': { backgroundColor: '#272727' },
        '.title': {
          color: 'white'
        },
        a: {
          variant: 'buttons.primary'
        }
      }
    },

    '.hamburger div, .hamburgerOpen div': {
      backgroundColor: 'light',
      borderRadius: '0px',
      height: '2px'
    },

    '.containerScrolled': {
      backgroundColor: 'dark'
    },
    '.socialContainer': {
      svg: {
        width: '25px',
        height: '25px',
        path: {
          fill: 'light'
        }
      }
    },
    '.navMenu': {
      padding: ['2.5rem', '', '', '2rem'],
      backgroundColor: 'dark',
      minWidth: '275px',
      '.navItem': {
        color: 'light',
        paddingBottom: '0.5rem',
        borderBottom: '1px solid #757575',
        width: '100%'
      }
    },

    '.navBlock': {
      backgroundColor: 'rgba(0,0,0,0.8)'
    },
    '.smallNavMenu  .navItem': {
      a: {
        color: 'light',
        fontSize: '1rem',
        color: 'white',
        ':hover': {
          color: 'secondary'
        }
      }
    }
  },

  footer: {
    borderTop: 'grey solid 1px',
    backgroundColor: 'dark',
    '.multiButtonContainer': {
      justifyContent: ['center', '', '', 'flex-start'],
      a: {
        padding: ['', '', '', '0.75rem 0.5rem 0.75rem 0rem']
      }
    },

    '.contactDetails-container': {
      marginBottom: '1rem'
    },
    '.locationSwitcherV1': {
      marginTop: '1rem',
      '.button': {
        variant: 'buttons.primary',
        flexDirection: 'row',
        svg: {
          marginRight: '0.5rem'
        }
      }
    },
    '.footerAboutButton .ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  ctaWidget: {
    zIndex: '999',
    color: 'light'
  },

  pageHero: {
    alignItems: 'flex-end',
    clipPath: 'polygon(100% 0%, 100% 95%, 50% 100%, 0 95%, 0 0)',
    '.content': {
      h1: { marginBottom: '4rem' }
    }
  },

  // ? ==========================
  // ? ======  Homepage  ========
  // ? ==========================

  homepageHero: {
    '.title': {
      borderBottom: '2px solid',
      borderLeft: 'none',
      paddingLeft: '0rem',
      textTransform: 'uppercase',
      letterSpacing: '-3px'
    },
    '.section': {
      margin: '1rem'
    }
  },

  homepageHeroShout: {},

  shoutSection: {
    padding: ['1rem', '3rem 2rem', '4rem 3rem', '4rem 5rem', '4rem 10rem'],
    // marginBottom: '3rem',
    '.imageContainer ': {
      width: ['', '50%', '40%']
    },
    '.content': {
      width: ['', '50%', '60%'],
      // backgroundColor: '#560c07',
      color: 'white',
      // position: 'relative',
      // top: ['', '3rem'],
      textAlign: 'left',
      alignItems: 'flex-start',
      padding: ['1rem', '2rem', '3rem', '3rem']
    },
    '.date, .title, .text': {
      textAlign: 'left'
    },

    '.title': {
      fontSize: '1.5rem',
      textTransform: 'uppercase',
      borderBottom: '3px solid',
      borderColor: 'grey',
      paddingBottom: '1rem'
    },

    '.date': {
      opacity: '0.8'
    },

    '.text': {
      fontSize: ['1.3rem', '1.1rem', '1.3rem'],
      lineHeight: ['1.5', '', '', '2']
      // borderLeft: '3px solid #be2f27',
      // paddingLeft: '2rem',
    },

    '.image': {
      borderRadius: '0px',
      borderLeft: '6px solid',
      borderColor: 'primary'
    }
  },

  homepageAbout: {
    backgroundColor: '#292929',
    padding: ['', '2rem', '3rem', '7rem 5rem', '10rem 6rem'],
    '.lazyload-wrapper': {
      maxHeight: '60vh',
      borderLeft: '6px solid',
      borderColor: 'primary'
    },

    '.title': {
      borderBottom: '3px solid',
      borderColor: 'grey',
      paddingBottom: '1rem',
      fontSize: ['', '', '', '2.5rem'],
      textTransform: 'uppercase'
    },
    '.content': {
      textAlign: 'left',
      alignItems: 'flex-start',
      padding: ['1rem', '2rem', '3rem', '', '4rem']
    },
    '.text': {
      opacity: '0.8'
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  homepageGallery: {
    variant: 'customVariants.homepageAbout',
    backgroundColor: '#292929'
  },

  homepageSpecials: {
    variant: 'customVariants.homepageAbout',
    backgroundColor: '#292929'
  },

  homepageTestimonials: {
    padding: ['2rem 1rem', '2rem', '4rem 3rem'],
    maxWidth: 'unset',
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',

    // '.innerMenuContainer': {
    //   border: 'solid 1px grey',
    // },
    '.menuSectionTitle': {
      margin: '1rem auto',
      fontSize: ['2rem', '', '', '3rem'],
      textTransform: 'uppercase',
      fontWeight: '300',
      textAlign: 'center',
      borderBottom: '3px solid',
      borderColor: 'grey',
      paddingBottom: '1rem',
      width: 'fit-content',
      color: 'primary'
    },

    '.menuSectionDescription': {
      textAlign: 'left',
      fontSize: ['1.5rem', '', '2rem'],
      opacity: '0.6',
      textAlign: 'center',
      margin: ['1.5rem', '', '4rem 2rem 2rem']
    },
    '.menuItemContainer': {
      height: '300px',
      minHeight: 'none',
      height: 'auto',
      display: 'flex',
      alignItems: 'center',
      overflowY: 'scroll',
      MsOverflowStyle: 'none',
      scrollbarWidth: 'none',
      '::-webkit-scrollbar': {
        display: 'none'
      }
    },

    '.menuItemInnerContainer, .menuItemContentContainer ': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },

    '.menuItemNameContainer': {
      order: '4',
      marginTop: '1rem'
    },
    '.menuItemName': {
      color: 'light',
      fontSize: ['1.1rem', '', '1.4rem'],
      textAlign: 'center',
      lineHeight: '1.5',
      width: '100%',
      fontFamily: 'heading',
      padding: ['0rem', '', '0rem 1rem']
    },

    '.menuItemDescription': {},

    '.slick-slider': {
      maxWidth: '767px',
      margin: '0 auto'
    }
  },

  homepageOnlineOrderingTitle: {
    padding: '1rem',
    paddingTop: ['3rem', '', '', '10rem'],
    // backgroundColor: '#292929',
    '.title': {
      color: 'primary',
      fontSize: ['2rem', '', '', '3rem'],
      fontWeight: '300',
      borderBottom: '3px solid',
      borderColor: 'grey',
      paddingBottom: '1rem',
      width: 'fit-content',
      margin: '0 auto 1rem',
      textTransform: 'uppercase'
    },
    '.subtitle': {
      opacity: '0.8'
    }
  },

  homepageOrderOnline: {
    // backgroundColor: '#292929',
    paddingBottom: ['3rem', '', '', '10rem'],
    '.contentBoxes': {
      maxWidth: '1200px',
      margin: '0 auto'
    },
    '.box': {
      width: ['50%', '', '25%'],
      alignItems: 'center',
      padding: ['1rem', '2rem']
    },
    '.image': {
      filter: 'brightness(0) invert(1)',
      opacity: '0.75',
      maxHeight: '60px',
      width: 'auto',
      transition: 'all ease-in-out 0.8s',
      ':hover': {
        opacity: '1',
        transform: 'scale(1.2)'
      }
    }
  },

  homepageSlider: {},

  homepageContentBoxes: {},

  homepageContentSection: {
    color: 'white',
    padding: '20vh 1rem',
    '.ctaButton': { variant: 'buttons.primary', padding: '1rem' }
  },

  // ? ==========================
  // ? =====  About Page  =======
  // ? ==========================

  aboutSection: {},

  // ? ==========================
  // ? ======  Menu Page  =======
  // ? ==========================

  menu: {
    '.dropdownContainer': {
      display: 'none'
    },
    '.cellImageContainer': {
      backgroundColor: 'primary'
    },
    '.cellImageLogo': {
      display: 'none'
    },
    marginY: '2rem',
    border: 'solid 1px',
    borderColor: '799d3d',
    '*': {
      boxShadow: 'none'
    },
    '.menuLogo': {
      marginBottom: '2rem'
    },
    '.menuSectionTitle': {
      color: 'primary',
      fontWeight: '300',
      borderBottom: 'solid 2px',
      borderColor: 'primary',
      paddingBottom: '0.5rem',
      textAlign: 'left',
      textTransform: 'uppercase',
      width: '95%',
      margin: ['0rem 0rem 1rem 0.5rem', '', '0rem 1.5rem 1.5rem']
    },
    '.menuSectionDescription': {
      marginBottom: '2rem'
    },
    '.menuItemContainer': {
      margin: '0rem'
    },
    '.menuItemName': {
      color: 'light',
      textTransform: 'uppercase',
      // fontWeight: 'bold',
      fontSize: ['1rem', '1.15rem', '1.25rem'],
      margin: '0rem',
      fontFamily: 'heading'
    },
    '.menuItemContentContainer': {
      padding: '0rem',
      margin: '0rem'
    },
    '.menuItemPrice': {
      color: 'primary',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontSize: ['1rem', '1.15rem', '1.25rem'],
      fontFamily: 'heading'
    },
    '.menuItemDescription': {
      fontSize: '1rem',
      fontWeight: '600',
      opacity: '0.6'
    },
    '.section-cell': {
      width: ['100%', 'calc(50% - (0.5rem * 2))', 'calc(33.333% - (0.5rem * 2))'],
      borderRadius: '0px',
      backgroundColor: 'transparent',
      border: 'none',
      position: 'relative'
    },
    '.menuCell': {
      borderRadius: '0px'
    },

    '.cellImage': {
      borderRadius: '0px'
    },

    '.cellName': {
      position: 'absolute',
      transform: 'translate(-50%,-50%)',
      textAlign: 'center',
      width: '100%',
      top: '50%',
      left: '50%',
      color: 'light',
      textTransform: 'uppercase',
      fontSize: ['1rem', '1.15rem', '1.75rem'],
      margin: '0rem',
      fontFamily: 'heading',
      padding: '0.5rem'
    }
  },

  // ? ==========================
  // ? =====  Gallery Page  =====
  // ? ==========================

  gallery: {},

  // ? ==========================
  // ? =====  Events Page  ======
  // ? ==========================

  events: {},

  // ? ==========================
  // ? =====  Contact Page  =====
  // ? ==========================

  locationsMap: {},

  contactForm: {
    backgroundColor: 'dark',
    color: 'white',
    '.inputField, .textField ': {
      borderColor: 'primary',
      borderRadius: '0px',
      color: 'white',
      '::placeholder': {
        color: 'white'
      }
    }
  }
}
